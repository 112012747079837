<template>
	<v-autocomplete
		v-model="inputvalue"
		name="title"
		clearable
		auto-select-first	
		label="Tittel"
		cache-items
		:items="ingredient_items"
		:search-input.sync="searchIngredients"
		required
	></v-autocomplete>
</template>
<script>

	export default {
		data:function(){
			return {
				inputvalue: '',
				ingredient_items: [],
				searchIngredients: '',
				loading:false,
				deboundIngredients:debounce(function(val){
					if(val === null) return;
					this.loading = true;
						/*if(connectedToLocalDb === true){
						if(localStorage.getItem("_query="+val) !== null){
						  this.loading = false;
						  this.items = JSON.parse(localStorage.getItem("_query="+val));
						  return;
						}
					}*/
					
					this.$axios.get('https://api.brukmat.no/ingredients/search/?ingredient='+val).then((res)=>{
						this.loading = false;

						let ingredients = res.data.map((item)=>{
							let keywords = item.keywords.join(", ");
							if(!keywords) return {product:item.product, raw:item};
							return {product: item.product + " - "+keywords, raw:item};
						});
						let products = ingredients.map((item)=>{
							return item.product;
						});
						
						this.ingredient_items = products;
						
						this.$db.then((db_obj)=>{
						var objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
						for(let ingredient of ingredients){
							let data = {
							ingredientName: ingredient.product,
							...ingredient.raw
							}
							console.log(data);
							objectStore.put(data);
						}
						});
						this.$db.then((db_obj)=>{
							var objectStore = db_obj.transaction(["queries"], "readwrite").objectStore("queries");
							let data = {
								searchValue: val,
								properties: products
							}
							objectStore.put(data);
							/*for(let product of products){
							let data = {
							  searchValue:product,
							  property:"Example property"
							}
							objectStore.put(data);
							console.log("Data stored");
							}*/
						});

						
					}).catch((err)=>{
						//this.snackbar = true;
						//this.snackbar_text = "En nettverksfeil oppstod";
						console.log(err);
					});
				}, 250)
		}},
		methods: {
			changeUsername() {
			//this.$emit('changeUsername')
			}
		},
		watch: {
			// When a user searches something
			searchIngredients(val){
				this.deboundIngredients(val);
			},
			inputvalue(val){
				console.log("Selected", val);
				this.$emit('ingredient_selected', val);
			}

		}
	}
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
</script>
