<template>
	<v-container>
		<SearchIngredients  @ingredient_selected="ingredient_selected($event)"/>
		<v-textarea
			v-model="description"
			name="input-7-1"
			label="Default style"
			value=""
			hint="Tekst"
		></v-textarea>
		<v-btn
			@click="submit"
			block
			color="primary"
			elevation="2"
		>Lagre</v-btn>
		<v-btn
			@click="deleteDesc"
			block
			color="error"
			elevation="2"
		>Slett</v-btn>
		<v-snackbar
			v-model="snackbar"
			timeout="5000"
		>
			{{ snackbar_text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="blue"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<a v-if="preview_link !== ''" target="_blank" :href="/ingrediens/+preview_link">Forhåndsvisning {{preview_link}}</a>
	</v-container>
</template>
<script>
	import SearchIngredients from '../components/SearchIngredients';
	export default {
		components: {
			SearchIngredients
		},
		data(){
			return {
				title:{product:'',keywords:''},
				description:'',
				snackbar:false,
				snackbar_text:'',
				preview_link:''
			}
		},
		methods: {
			ingredient_selected (ingredient_title) {
				if(ingredient_title == null) {
					this.description = '';
				}
				this.preview_link = this.$encodeURL(ingredient_title);
				console.log("Changed detected",ingredient_title);
				let splitTitle = ingredient_title.split(" - ");
				let title = splitTitle[0],
					keywords = [];

				if(splitTitle.length > 1){
					keywords = splitTitle[1].split(", ");
				}
				console.log("title", title, "Keywords", keywords);
				let body = {
					product:title,
					keywords:keywords
				};
				this.title.product = title;
				this.title.keywords = keywords;
				console.log(body);
				let headers = {
					'Content-Type': 'application/json'
				};
				console.log(body);
				this.$axios.post('https://api.brukmat.no/get/ingredient/description', body, {headers:headers})
				.then((res)=>{
					console.log(res.data);
					console.log(res.data.http_code);
					this.description = res.data.data;
				})
				.catch(err=>{
					console.log(err)
					if(err.response.status === 404){
						this.snackbar_text = "Fant ingen tidligere data";
						this.snackbar = true;
						this.description = '';
					} else {
						this.snackbar_text = "Fikk en ukjent feil...";
						this.snackbar = true;
					}
				});
			},
			deleteDesc(){

				var localStorage = window.localStorage;
				let apiKey = localStorage.getItem("api_key");
				let fullTitle;
				if(this.title.keywords.length > 0) {
					fullTitle = this.title.product+'~'+this.title.keywords.join(",");
				} else {
					fullTitle = this.title.product;
				}
				if(fullTitle === '') return;
				let headers = {
					'api-key':apiKey
				};
				this.$axios.delete('https://api.brukmat.no/ingredient/description/?title='+fullTitle, {headers:headers}).then(res=>{
					console.log(res);
					this.snackbar_text = "Slettet";
					this.snackbar = true;
					this.description = ''
				}).catch(err=>{
					if(err.response.status === 404){
						this.snackbar_text = "Fant ikke ingrediens...";
						this.snackbar = true;
					} else {
						this.snackbar_text = "Fikk en ukjent feil...";
						this.snackbar = true;
					}
				});
			},
			submit(){
				console.log("Title product",this.title.product,"title keywords",this.title.keywords,"description",this.description);
				var localStorage = window.localStorage;
				let apiKey = localStorage.getItem("api_key");
				let body = {"product":this.title.product, "keywords":this.title.keywords, "description":this.description};
				let headers = {
					'Content-Type': 'application/json',
					'api-key':apiKey
				};
				this.$axios.post('https://api.brukmat.no/create/ingredient/description', body, {headers:headers}).then(res=>{
					console.log(res);
					this.snackbar_text = "Lagret";
					this.snackbar = true;
				}).catch(err=>{
					if(err.response.status === 401){
						this.snackbar_text = "Feil/Manglende API nøkkel";
						this.snackbar = true;
					}
				});
			}
		}
	}
</script>